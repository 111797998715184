import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Frame from "../components/frame";
import Hero from "../content/hero";
import Schedule from "../content/schedule";
import Section from "../content/section";
import Header from "../content/header";
import Footer from "../content/footer";
import Intro from "../content/intro";
import Tracks from "../content/tracks";
import Register from "../content/register";
import Rules from "../content/rules";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Header mdxType="Header" />
    <Hero mdxType="Hero"></Hero>
    <Section color='grey' name='intro' mdxType="Section">
  <Intro mdxType="Intro" />
    </Section>
    <Section color='green' name='register' mdxType="Section">
  <Register mdxType="Register" />
    </Section>
    <Section color='yellow' name='schedule' mdxType="Section">
  <Schedule mdxType="Schedule" />
    </Section>
    <Section color='grey' name='tracks' mdxType="Section">
  <Tracks mdxType="Tracks" />
    </Section>
    <Section color='red' name='rules' mdxType="Section">
  <Rules mdxType="Rules" />
    </Section>
    <Footer mdxType="Footer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      