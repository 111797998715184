import React from "react";
import { MDXProvider } from "@mdx-js/react";
import Text from "../text";
import Link from "../link";
import { MDXRenderer } from "gatsby-plugin-mdx";

const defaultComponents = {
  h1: (props) => <Text spacing='auto' size='xx-large' {...props} />,
  h2: (props) => <Text spacing='auto' size='x-large' {...props} />,
  h3: (props) => <Text spacing='auto' size='large' {...props} />,
  h4: (props) => (
    <Text spacing='auto' size='medium' weight='medium' {...props} />
  ),
  p: (props) => <Text spacing='auto' size='medium' {...props} />,
  a: (props) => <Link {...props} />,
  li: (props) => <Text size='medium' tag='li' {...props} />,
};

const Mdx = ({ children, components }) => (
  <MDXProvider components={{ ...defaultComponents, ...components }}>
    <MDXRenderer>{children.childMdx?.body || children}</MDXRenderer>
  </MDXProvider>
);

export default Mdx;
