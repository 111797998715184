import React from "react";
import logo from "../../images/schuss-schotter-logo.svg";
import * as styles from "./footer.module.scss";
import classNames from "classnames";
import Text from "../../components/text";
import Link from "../../components/link";
import { graphql, useStaticQuery } from "gatsby";
import Picture from "../../components/picture";

const Footer = () => {
  const {
    contentfulOutro: {
      image: { fluid },
    },
  } = useStaticQuery(graphql`
    query {
      contentfulOutro {
        id
        image {
          fluid(maxWidth: 1440, quality: 80) {
            srcSetWebp
            src
            srcSet
          }
        }
      }
    }
  `);
  return (
    <footer className={classNames(styles.footer)}>
      <div className={classNames(styles.content)}>
        <Picture
          className={styles.image}
          src={fluid.src}
          srcSet={fluid.srcSet}
          srcSetWebp={fluid.srcSetWebp}
        />
        <img src={logo} className={classNames(styles.logo)} />
      </div>
      <div className={classNames(styles.nav)}>
        <Text size='x-small' weight='medium'>
          &copy; 2021 Schuss & Schotter ·{" "}
          <Link href='/imprint'>Imprint & Contact</Link>
        </Text>
      </div>
    </footer>
  );
};

export default Footer;
