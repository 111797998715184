import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import * as styles from "./navigation.module.scss";

const Action = ({ children, isSelected, className }) => {
  const actionRef = useRef();
  function scrollIntoView(delay = 0) {
    const parentWidth = actionRef.current.parentElement.getBoundingClientRect()
      .width;
    const actionWidth = actionRef.current.getBoundingClientRect().width;
    setTimeout(() => {
      actionRef.current.parentElement.scrollTo({
        left: actionRef.current.offsetLeft + actionWidth / 2 - parentWidth / 2,
        behavior: "smooth",
      });
    }, delay);
  }

  return (
    <div
      onClick={scrollIntoView}
      ref={actionRef}
      className={classNames(styles.action, className)}
    >
      {children}
    </div>
  );
};

export default Action;
