import classNames from "classnames";
import React from "react";

import * as styles from "./card.module.scss";

const Card = ({ children, isSelectable, isSelected, className, ...others }) => {
  return (
    <div
      className={classNames(
        styles.card,
        {
          [`${styles.isSelected}`]: isSelected,
          [`${styles.isSelectable}`]: isSelectable,
        },
        className
      )}
      {...others}
    >
      {children}
    </div>
  );
};

export default Card;
