import classNames from "classnames";
import React from "react";
import * as styles from "./section.module.scss";

const Section = ({ children, name, fullbleed, color }) => {
  return (
    <section
      className={classNames(
        styles.section,
        { [styles.fullbleed]: fullbleed },
        styles[color]
      )}
    >
      {name && <a className={classNames(styles.anchor)} name={name} />}
      <div className={classNames(styles.content)}>{children}</div>
    </section>
  );
};

Section.defaultProps = {
  color: "grey",
};

export default Section;
