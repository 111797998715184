import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Button from "../../components/button";
import Checkbox from "../../components/checkbox";
import Input from "../../components/input";
import Link from "../../components/link";
import Mdx from "../../components/mdx";
import Select from "../../components/select";
import Text from "../../components/text";
import Form from "./form";

import * as styles from "./register.module.scss";

const Register = () => {
  const {
    contentfulRegister: { headline, content },
  } = useStaticQuery(graphql`
    query {
      contentfulRegister {
        content {
          childMdx {
            body
          }
        }
        headline
      }
    }
  `);

  return (
    <>
      <Text
        tag='h2'
        spacing='auto'
        className={classNames(styles.headline)}
        weight='bold'
        uppercase
        size='x-large'
      >
        {headline}
      </Text>
      <Mdx>{content}</Mdx>
    </>
  );
};

export default Register;
