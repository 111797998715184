import React from "react";
import classNames from "classnames";
import * as styles from "./stack.module.scss";

const Item = ({ children, isSelected, className, ...others }) => {
  return (
    <div
      className={classNames(
        styles.item,
        { [`${styles.isSelected}`]: isSelected },
        className
      )}
      {...others}
    >
      {children}
    </div>
  );
};

Item.defaultProps = {
  isSelected: false,
};

export default Item;
