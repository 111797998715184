import React from "react";

const InfoIcon = () => (
  <svg
    style={{ verticalAlign: "-0.222em" }}
    width='1.2em'
    height='1.2em'
    viewBox='0 0 22 22'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.5 11C2.5 6.30558 6.30558 2.5 11 2.5C15.6921 2.50551 19.4945 6.30786 19.5 11C19.5 15.6944 15.6944 19.5 11 19.5C6.30558 19.5 2.5 15.6944 2.5 11ZM18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18C14.866 18 18 14.866 18 11ZM12.25 7.56C12.25 8.11228 11.8023 8.56 11.25 8.56C10.6977 8.56 10.25 8.11228 10.25 7.56C10.25 7.00772 10.6977 6.56 11.25 6.56C11.8023 6.56 12.25 7.00772 12.25 7.56ZM8.25 11V10.5C8.25 10.2239 8.47386 10 8.75 10H11.75C12.0261 10 12.25 10.2239 12.25 10.5V15.5C12.25 15.7761 12.0261 16 11.75 16H11.25C10.9739 16 10.75 15.7761 10.75 15.5V11.5H8.75C8.47386 11.5 8.25 11.2761 8.25 11Z'
      fill='currentColor'
    />
  </svg>
);

export default InfoIcon;
