import classNames from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Button from "../../components/button";
import Picture from "../../components/picture";
import * as styles from "./hero.module.scss";
import logo from "../../images/schuss-schotter-logo.svg";

const Hero = () => {
  const {
    contentfulHero: {
      image: { fluid },
    },
  } = useStaticQuery(graphql`
    query HeroQuery {
      contentfulHero {
        id
        image {
          fluid(maxWidth: 1440, quality: 80) {
            srcSetWebp
            src
            srcSet
          }
        }
      }
    }
  `);
  return (
    <div className={classNames.hero}>
      <Picture
        className={styles.image}
        src={fluid.src}
        srcSet={fluid.srcSet}
        srcSetWebp={fluid.srcSetWebp}
      />
      <div className={classNames(styles.content)}>
        <img
          className={classNames(styles.logo)}
          src={logo}
          alt='Schuss & Schotter Logo'
        />
      </div>
    </div>
  );
};

export default Hero;
