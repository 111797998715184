import React from "react";
import Fact from "../../components/fact";
import Mdx from "../../components/mdx";

const ScheduleItem = ({ className, headline, time, description }) => {
  return (
    <div className={className}>
      <Fact label={time}>{headline}</Fact>
      <Mdx>{description}</Mdx>
    </div>
  );
};

export default ScheduleItem;
