import classNames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import Text from '../../components/text';
import ScheduleItem from './schedule-item';

import * as styles from './schedule.module.scss';

const Schedule = () => {
    const { contentfulSchedule: { headline, items }} = useStaticQuery(graphql`
    query ScheduleQuery {
        contentfulSchedule {
            headline
            items {
              headline
              time
              description {
                childMdx {
                    body
                }
              }
            }
          }
    }
    `)
    return <div>
            <Text tag="h2" className={classNames(styles.headline)} weight="bold" uppercase size="x-large">{headline}</Text>
            <div>
                {items.map(({ headline, time, description}) => (
                    <ScheduleItem className={classNames(styles.item)} key={time} headline={headline} time={time} description={description} />
                ))}
            </div>
    </div>
}

export default Schedule;