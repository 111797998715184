import classNames from "classnames";
import React from "react";
import * as styles from "./navigation.module.scss";

const Navigation = ({ children, className }) => {
  return (
    <div className={classNames(styles.navigation, className)}>{children}</div>
  );
};

export default Navigation;
