import classNames from "classnames";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import React, { useEffect, useRef, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Text from "../../components/text";
import Link from "../../components/link";
import Mdx from "../../components/mdx";
import Stack from "../../components/stack";

import TrackDetail from "../track-detail/track-detail";
import Card from "../../components/card";
import Navigation from "../../components/navigation";
import * as styles from "./tracks.module.scss";
import TrackPreview from "../track-preview";
import InfoIcon from "../../components/icons/info";

mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken =
  "pk.eyJ1Ijoic2NodXNzLXNjaG90dGVyIiwiYSI6ImNrbXdnazRqcDBldmkyc3FpZ2FhOG80engifQ.z4pyjXn19MxXfgmYI4sOlg";

const Tracks = () => {
  const map = useRef();
  const scrollRef = useRef();
  const mapContainer = useRef();
  const selectedTrackId = useRef();
  const highlightedTrackId = useRef();
  const [showInfo, setShowInfo] = useState(false);

  const [, setForceUpdate] = useState(Date.now());

  function forceUpdate() {
    setForceUpdate(Date.now());
  }

  const {
    contentfulTracks: { headline, content, tracks },
  } = useStaticQuery(graphql`
    query {
      contentfulTracks {
        headline
        content {
          childMdx {
            body
          }
        }
        tracks {
          coreFacts {
            label
            value
          }
          difficulty
          description {
            childMdx {
              body
            }
          }
          mapId
          name
          surfaceFacts {
            value
            label
          }
          geoJson {
            geometry {
              coordinates
            }
          }
        }
      }
    }
  `);

  function handleMapTrackMouseEnter(e) {
    map.current.getCanvas().style.cursor = "pointer";
    const layerId = e.features[0].layer.id.replace("-interactive", "");

    highlightTrack(layerId);
  }

  function handleMapTrackMouseLeave() {
    map.current.getCanvas().style.cursor = "";
    tracks.forEach((track) => {
      muteTrack(track.mapId);
    });
  }

  function highlightTrack(id) {
    highlightedTrackId.current = id;

    map.current.setPaintProperty(id, "line-color", "#1b1047");
    forceUpdate();
  }

  function muteTrack(id) {
    highlightedTrackId.current = null;
    const isSelected = id === selectedTrackId.current;
    if (selectedTrackId.current) {
      map.current.setPaintProperty(id, "line-opacity", 1);
      map.current.setPaintProperty(
        id,
        "line-color",
        isSelected ? "#1b1047" : "#D1CEDA"
      );
    } else {
      map.current.setPaintProperty(id, "line-opacity", 1);
      map.current.setPaintProperty(id, "line-color", "#1b1047");
    }
    forceUpdate();
  }

  function selectTrack(id, fitBounds = true, scrollIntoView = false) {
    selectedTrackId.current = id;

    tracks.forEach((track) => {
      map.current.setPaintProperty(track.mapId, "line-color", "#D1CEDA");
    });

    map.current.moveLayer(id);
    map.current.setPaintProperty(id, "line-color", "#1b1047");
    map.current.setPaintProperty(id, "line-opacity", 1);
    map.current.setPaintProperty(`${id}-interactive`, "line-opacity", 1);

    const track = tracks.filter((t) => t.mapId === id)[0];

    if (track && fitBounds) {
      const coordinates = track.geoJson.geometry.coordinates;
      var bounds = coordinates.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
      map.current.fitBounds(bounds, {
        padding: 40,
      });
    }
    if (scrollIntoView) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    forceUpdate();
  }

  function handleMapClick(e) {
    var features = map.current.queryRenderedFeatures(e.point, {
      layers: tracks.map((track) => `${track.mapId}-interactive`),
    });

    if (!features.length) {
      return;
    }

    const layerId = features[0].layer.id.replace("-interactive", "");

    selectTrack(layerId);
  }

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/schuss-schotter/ckoadp4lr018b18nqd96rofo3",
      center: [13.1, 47.674],
      zoom: 10,
    });

    map.current.addControl(new mapboxgl.NavigationControl());

    map.current.scrollZoom.disable();

    map.current.on("load", () => {
      tracks.forEach((track) => {
        map.current.addSource(`${track.mapId}`, {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: track.geoJson.geometry.coordinates,
            },
          },
        });
        map.current.addLayer({
          id: `${track.mapId}-interactive`,
          type: "line",
          source: `${track.mapId}`,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#ffffff",
            "line-width": 8,
            "line-opacity": 1,
          },
        });
        map.current.addLayer({
          id: `${track.mapId}`,
          type: "line",
          source: `${track.mapId}`,
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#888",
            "line-width": 4,
          },
        });

        map.current.on(
          "mouseenter",
          `${track.mapId}-interactive`,
          handleMapTrackMouseEnter
        );
        map.current.on(
          "mouseleave",
          `${track.mapId}-interactive`,
          handleMapTrackMouseLeave
        );
      });
      map.current.on("click", handleMapClick);

      selectTrack(tracks[0].mapId, false);
    });
  }, []);

  return (
    <div>
      <Text
        tag='h2'
        className={classNames(styles.headline)}
        weight='bold'
        uppercase
        size='x-large'
      >
        {headline}
      </Text>
      <div className={classNames(styles.content)}>
        <Mdx>{content}</Mdx>
      </div>
      <Navigation className={classNames(styles.trackNavigation)}>
        {tracks.map((track) => (
          <Navigation.Action
            className={classNames(styles.trackNavigationItem)}
            key={track.mapId}
            isSelected={selectedTrackId.current === track.mapId}
            style={{ width: "80vw" }}
          >
            <Card
              className={classNames(styles.trackNavigationCard)}
              onClick={() => selectTrack(track.mapId)}
              isSelectable
              isSelected={selectedTrackId.current === track.mapId}
            >
              <TrackPreview
                {...track}
                showInfo={showInfo}
                cta={
                  !showInfo &&
                  selectedTrackId.current === track.mapId && (
                    <Text size='x-small'>
                      <Link onClick={() => setShowInfo(true)}>
                        <InfoIcon /> Show more
                      </Link>
                    </Text>
                  )
                }
              ></TrackPreview>
            </Card>
          </Navigation.Action>
        ))}
      </Navigation>
      <div className={classNames(styles.map)} ref={scrollRef}>
        <Stack className={classNames(styles.trackStack)}>
          {tracks.map((track) => (
            <Stack.Item
              isSelected={selectedTrackId.current === track.mapId}
              key={track.mapId}
            >
              <Card
                role='button'
                tabIndex='0'
                className={classNames(styles.trackCard)}
                onClick={() => selectTrack(track.mapId)}
                onMouseEnter={() => highlightTrack(track.mapId)}
                onMouseLeave={() => muteTrack(track.mapId)}
                isSelectable
                isSelected={selectedTrackId.current === track.mapId}
              >
                <TrackDetail {...track}></TrackDetail>
              </Card>
            </Stack.Item>
          ))}
        </Stack>

        <div
          className={classNames(styles.mapContainer)}
          ref={mapContainer}
        ></div>
      </div>
    </div>
  );
};

export default Tracks;
