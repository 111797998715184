import classNames from "classnames";
import React from "react";
import Text from "../text";
import * as styles from "./badge.module.scss";

const Badge = ({ size, children }) => {
  return (
    <span className={classNames(styles.badge, styles[size])}>
      <Text weight='medium' size='x-small'>
        {children}
      </Text>
    </span>
  );
};

Badge.defaultProps = {
  size: "medium",
};

export default Badge;
