import classNames from "classnames";
import React from "react";
import Badge from "../../components/badge";
import Fact from "../../components/fact";
import Mdx from "../../components/mdx";
import Text from "../../components/text";
import * as styles from "./track-preview.module.scss";

const TrackPreview = ({
  name,
  difficulty,
  description,
  coreFacts,
  surfaceFacts,
  isSelected,
  showInfo,
  cta,
}) => {
  return (
    <div
      className={classNames(styles.trackPreview, {
        [`${styles.isSelected}`]: isSelected,
      })}
    >
      <div className={classNames(styles.header)}>
        <div className={classNames(styles.titleColumn)}>
          <Text
            weight='medium'
            size='small'
            className={classNames(styles.title)}
          >
            {name}
          </Text>
        </div>
        <div className={classNames(styles.difficultyColumn)}>
          <Badge>{difficulty}</Badge>
        </div>
      </div>
      <div className={classNames(styles.content)}>
        <div className={classNames(styles.coreFacts)}>
          {coreFacts.map((fact) => (
            <Fact size='small' key={fact.label} label={fact.label}>
              {fact.value}
            </Fact>
          ))}
        </div>
        {showInfo && (
          <div>
            <Mdx
              components={{
                p: (props) => <Text size='x-small' {...props}></Text>,
              }}
            >
              {description}
            </Mdx>
          </div>
        )}
        {showInfo && (
          <div className={classNames(styles.surfaceFacts)}>
            {surfaceFacts.map((fact) => (
              <Fact size='small' key={fact.label} label={fact.label}>
                {fact.value}
              </Fact>
            ))}
          </div>
        )}
        {cta && <div>{cta}</div>}
      </div>
    </div>
  );
};

export default TrackPreview;
