import React from "react";
import classNames from "classnames";

import * as styles from "./fact.module.scss";
import Text from "../text";

const Fact = ({ label, direction, size, children }) => {
  const content = [
    <Text key='label' size={size === "small" ? "x-small" : "small"}>
      {label}
    </Text>,
    <Text
      className={classNames(styles.value)}
      key='value'
      size={size === "small" ? "medium" : "large"}
    >
      {children}
    </Text>,
  ];

  if (direction === "row") {
    content.reverse();
  }

  return <div className={classNames(styles[direction])}>{content}</div>;
};

Fact.defaultProps = {
  direction: "column",
  size: "medium",
};

export default Fact;
