import React from "react";
import classnames from 'classnames';
import * as styles from './picture.module.scss';

const Picture = ({
  sizes,
  srcSet,
  src,
  alt,
  srcSetWebp,
  className,
  ...rest
}) => (
  <picture>
    {srcSetWebp && <source srcSet={srcSetWebp} type='image/webp' />}
    <img
      className={classnames(className, styles.image)}
      src={src}
      srcSet={srcSet}
      alt={alt}
      sizes={sizes}
      {...rest}
    />
  </picture>
);

export default Picture;
