import classNames from 'classnames';
import React from 'react';

import * as styles from './link.module.scss';

const Link = ({children, appearance, ...rest}) => {
 return <a className={classNames(styles.link, styles[appearance])} {...rest}>{children}</a>
}

Link.defaultProps = {
    appearance: 'underline'
}

export default Link;