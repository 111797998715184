// extracted by mini-css-extract-plugin
export const map = "tracks-module--map--2pl53";
export const mapboxglMap = "tracks-module--mapboxgl-map--3eyuG";
export const mapboxglCanvas = "tracks-module--mapboxgl-canvas--198OK";
export const mapContainer = "tracks-module--map-container--2BX3U";
export const headline = "tracks-module--headline--2_s5-";
export const content = "tracks-module--content--2zf5h";
export const trackNavigation = "tracks-module--track-navigation--1ghWb";
export const trackNavigationCard = "tracks-module--track-navigation-card--1I-rT";
export const trackNavigationItem = "tracks-module--track-navigation-item--1I_m1";
export const trackStack = "tracks-module--track-stack--2UzYv";
export const trackCard = "tracks-module--track-card--1VpIS";