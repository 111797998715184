import React from "react";
import classNames from "classnames";
import * as styles from "./intro.module.scss";
import { graphql, useStaticQuery } from "gatsby";
import Fact from "../../components/fact";
import Mdx from "../../components/mdx";
import Picture from "../../components/picture";
import Text from "../../components/text";

const Intro = ({ data }) => {
  const {
    contentfulIntro: { content, facts },
    contentfulSupporters: { headline, supporters },
  } = useStaticQuery(graphql`
    query IntroQuery {
      contentfulSupporters {
        headline
        supporters {
          logo {
            fluid(maxWidth: 200, quality: 80) {
              srcSetWebp
              src
              srcSet
            }
          }
          name
          url
        }
      }
      contentfulIntro {
        content {
          childMdx {
            body
          }
        }
        facts {
          label
          value
        }
      }
    }
  `);

  return (
    <>
      <div className={classNames(styles.facts)}>
        {facts.map(({ label, value }) => (
          <Fact key={label} label={label}>
            {value}
          </Fact>
        ))}
      </div>
      <div className={classNames(styles.content)}>
        <Mdx>{content.childMdx.body}</Mdx>
      </div>

      <Text
        spacing='auto'
        size='small'
        weight='medium'
        style={{ textAlign: "center" }}
      >
        {headline}
      </Text>
      <div className={styles.supporters}>
        {supporters.map((supporter) => (
          <a
            key={supporter.name}
            className={styles.supporter}
            href={supporter.url}
            target='_blank'
            rel='noreferrer'
          >
            <Picture
              className={styles.supporterImage}
              src={supporter.logo.fluid.src}
              srcSet={supporter.logo.fluid.srcSet}
              srcSetWebp={supporter.logo.fluid.srcSetWebp}
            />
          </a>
        ))}
      </div>
    </>
  );
};

export default Intro;
